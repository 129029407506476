export interface IOrganization {
  address: null | string;
  dateAdded: string;
  id: number;
  missionStatement: string;
  name: string;
  noOfCommunities: number;
  noOfPrograms: number;
  noOfAdmins: number;
  noOfMembers: number;
  organizationAdmins: OrganizationAdmin[];
  assignedOrganizationAdmins: OrganizationAdmin[];
  organizationCommunities: OrganizationCommunity[];
  organizationImage: {
    file: {
      original: null | string;
      thumb: null | string;
    };
    id: null | number;
    path: null | string;
  };
  coverImage: {
    id: number | null;
    large: string | null;
    original: string | null;
    thumb: string | null;
  };
  organizationTags: OrganizationTag[];
  programs: any[];
  status: string;
  contactInfo: ContactInfo[];
  openAccess: boolean;
  appName: string;
}

interface OrganizationAdmin {
  id: number;
  name: string;
  profileImage: {
    file: null | string;
    id: null | number;
    path: null | string;
  };
}

interface OrganizationCommunity {
  date: string;
  id: number;
  name: string;
  noOfMembers: number;
  profileImage: {
    file: null | string;
    id: null | number;
    path: null | string;
  };
}

interface OrganizationTag {
  id: number;
  name: string;
}

interface ContactInfo {
  description: string;
  emails: [
    {
      email: string;
      title: string;
    },
  ];
  links: [
    {
      links: string;
      title: string;
    },
  ];
  organizationId: number;
  phones: [
    {
      phoneNumber: string;
      title: string;
    },
  ];
}

const initialOrganizationData: IOrganization = {
  address: null,
  dateAdded: "",
  id: 0,
  missionStatement: "",
  name: "",
  noOfCommunities: 0,
  noOfPrograms: 0,
  noOfAdmins: 0,
  noOfMembers: 0,
  organizationAdmins: [],
  assignedOrganizationAdmins: [],
  organizationCommunities: [],
  organizationImage: {
    file: {
      original: null,
      thumb: null,
    },
    id: null,
    path: null,
  },
  coverImage: {
    id: null,
    large: null,
    original: null,
    thumb: null,
  },
  organizationTags: [],
  programs: [],
  status: "active",
  contactInfo: [],
  openAccess: false,
  appName: ""
};

export { initialOrganizationData };
