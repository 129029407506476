import generateUniqueId from "generate-unique-id";
import { panelNames } from "..";
import {
  convertJsonOfGeographicalPanel,
  convertJsonOfHarvestPanel,
  convertUpComingJsonOfGeographicalPanel,
  convertUpComingJsonOfHarvestPanel,
  convertUpComingQuestion,
} from "../dataConverters";
import {
  GENERAL_INFO_QUESTIONS_NAME,
  SAMPLE_QUESTIONS_NAME,
  SPECIES_DETAILS_QUESTIONS_NAME,
} from "../panelsQuestionsName";
import { panelsName } from "../panelsTitle";
import questionTypes from "../questionTypes";
import convertQuestionObj, {
  convertTheChoicesOfQuestions,
} from "./convertQuestionObj";
import { v4 as uuidv4 } from "uuid";

function structureFormJSON(
  originalJson: any,
  panelsJson: any,
  globalStoreData: any,
  format = "request",
  dataSourcesData?: any
) {
  const panels = Object.values(panelsName);

  const updatedJson = structuredClone(originalJson);
  const elements = updatedJson.pages[0].elements;
  let updatedElements;

  if (format === "request") {
    updatedElements = elements?.map((ele: any, index: number) => {
      if (panels.toString().includes(ele.name)) {
        const panel = panelsJson.find((p: any) => p.name === ele.name);
        if (ele.name === panelsName.HARVEST) {
          return convertJsonOfHarvestPanel(ele, globalStoreData, ele);
        } else if (ele.name === panelsName.GEOGRAPHICAL_INFORMATION) {
          return convertJsonOfGeographicalPanel(ele, globalStoreData, ele);
        } else {
          const updatedPanelJson = {
            ...ele,
          };

          const updatedElements = updatedPanelJson.elements?.map((e: any) => {
            if (
              (e.type === questionTypes.tagbox ||
                e.type === questionTypes.dropdown) &&
              e.choicesByUrl &&
              e.choicesByUrl.url
            ) {
              const updatedElement = { ...e };
              updatedElement.choices = setTheChoicesOfDropdowns(
                e.name,
                dataSourcesData
              );
              updatedElement.elementId = uuidv4();
              updatedElement.questionId = uniqueIdGenertorInNumber();
              const updatedElementWithChoices =
                convertTheChoicesOfQuestions(updatedElement);
              return updatedElementWithChoices;
            } else {
              return convertQuestionObj(e, globalStoreData);
            }
          });

          updatedPanelJson.elements = updatedElements
            ? [...updatedElements]
            : [];
          // console.log("updatedElements", updatedElements)

          return updatedPanelJson;
        }
      }
      if (ele.type === questionTypes.paneldynamic) {
        const updatedTemplateElements = ele.templateElements?.map((e: any) => {
          return convertQuestionObj(e, globalStoreData);
        });

        ele.templateElements = updatedTemplateElements
          ? [...updatedTemplateElements]
          : [];

        return ele;
      } else if (questionTypes.panel && panelNames.includes(ele.name)) {
        const question = { ...updateElementsRequiredStatusDynamically(ele) };
        const updatedElements = question.elements?.map((e: any) => {
          return convertQuestionObj(e, globalStoreData);
        });
        updatedElements.elements = updatedElements ? [...updatedElements] : [];

        return updatedElements;
      } else if (ele.type === questionTypes.panel) {
        const updatedElements = ele.elements?.map((e: any) => {
          return convertQuestionObj(e, globalStoreData);
        });

        ele.elements = updatedElements ? [...updatedElements] : [];

        return ele;
      }

      return convertQuestionObj(ele, globalStoreData);
    });
  } else if (format === "response") {
    updatedElements = elements?.map((ele: any, index: number) => {
      // if element is panel then updating it
      if (panels.toString().includes(ele.name)) {
        if (ele.name === panelsName.HARVEST) {
          return convertUpComingJsonOfHarvestPanel(ele);
        } else if (ele.name === panelsName.GEOGRAPHICAL_INFORMATION) {
          return convertUpComingJsonOfGeographicalPanel(ele);
        } else if (ele.panelType === "static" && ele.type === "paneldynamic") {
          return {
            ...ele,
            type: "panel",
            name: ele.name,
            titleLocation: ele.titleLocation,
            elements: ele.templateElements,
          };
        }

        return {
          ...ele,
          type: ele.type,
          name: ele.name,
          titleLocation: ele.titleLocation,
        };
      } else {
        if (ele.elements) {
          const elements = ele.elements?.map((e: any) => {
            return convertUpComingQuestion(e);
          });

          ele.elements = elements ? [...elements] : [];
        } else if (ele.templateElements) {
          const templateElements = ele.templateElements?.map((e: any) => {
            return convertUpComingQuestion(e);
          });

          ele.templateElements = templateElements ? [...templateElements] : [];
        }
      }

      return ele;
    });
  }

  updatedJson.pages[0].elements = updatedElements;

  return updatedJson;
}

export default structureFormJSON;

function updateTemplateElementsRequiredStatusDynamically(panel: any) {
  if (panel.elements) {
  }
  panel.elements?.forEach((element: any) => {
    if (element.type !== "html") {
      let flagName =
        "show" + element.name.charAt(0).toUpperCase() + element.name.slice(1);

      if (panel.hasOwnProperty(flagName)) {
        element.isRequired = panel[flagName];
      } else {
        element.isRequired = true;
      }
    }
  });
  const updatedTemplateElements = panel.elements?.filter(
    (element: any) => element.type !== "html"
  );
  panel.elements = updatedTemplateElements;

  return panel;
}

function updateElementsRequiredStatusDynamically(panel: any) {
  panel.elements = panel.elements?.map((element: any) => {
    if (element.type === "html") {
      return element;
    }
    const capitalizedName =
      element.name.charAt(0).toUpperCase() + element.name.slice(1);
    const flagName = `show${capitalizedName}`;
    const requireFlagName = `require${element.name}`;
    if (panel.hasOwnProperty(flagName)) {
      element.isRequired = panel[flagName];
      panel[requireFlagName] = panel[flagName];
    }

    return element;
  });

  return panel;
}

function setTheChoicesOfDropdowns(
  fieldName: any,
  dataSourcesData: {
    observers: any[];
    types: any[];
    objects: any[];
    categories: any[];
    species: any[];
  }
) {
  switch (fieldName) {
    case SPECIES_DETAILS_QUESTIONS_NAME.Category:
      return dataSourcesData.categories;
    case SPECIES_DETAILS_QUESTIONS_NAME.SpecieName:
      return dataSourcesData.species;
    case SPECIES_DETAILS_QUESTIONS_NAME.Type:
      return dataSourcesData.types;
    case SPECIES_DETAILS_QUESTIONS_NAME.ObjectName:
      return dataSourcesData.objects;
    case SAMPLE_QUESTIONS_NAME.Sampler:
    case GENERAL_INFO_QUESTIONS_NAME.Observers:
    case GENERAL_INFO_QUESTIONS_NAME.DataEntry:
    case GENERAL_INFO_QUESTIONS_NAME.Reported:
      return dataSourcesData.observers;
    default:
      return [];
  }
}

export function uniqueIdGenertorInNumber() {
  const questionId = generateUniqueId({
    length: 8,
    useLetters: false,
    useNumbers: true,
  });
  return Number(questionId);
}
