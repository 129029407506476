import axios, { AxiosError, AxiosResponse } from "axios";
import Cookies from "universal-cookie";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { baseURL, currentEnvironment } from "../../config/index";
import {
  convertKeysToCamelCase,
  convertKeysToSnakeCase,
} from "utils/caseConvertor";
import {
  errorHandler,
  requestInterceptor,
  responseInterceptor,
} from "./interceptors";
import { getVersionAsync } from "utils/getCurrentAppVersion";

import { Toasts } from "view/components/Toasts";
import { useDispatch } from "react-redux";
import store from "../../store";
import { getRandomProgress } from "utils/getRandomProgress";
import { setLoadingProgress } from "store/loadingBar";
import moment from "moment";
import { openNotifyAboutNewVersionModalAction } from "store/modals/reducer.actions";
import { updateAuthPermissions } from "store/auth";

// const cookies = new Cookies();

// let appVersion: any = null;

// (async () => {
//   try {
//     appVersion = await getVersionAsync();
//     if (appVersion) {
//       console.log(`App version: ${appVersion}`);
//     }
//   } catch (error) {
//     console.error("Error:", error);
//   }
// })();

// /**
//  * Function to get the authentication token from cookies
//  */
// const getAuthToken = () => cookies.get("token") || "";

// const currentAppVersion = async () => {
//   const version = await getVersionAsync();
//   return version;
// };

const cookies = new Cookies();

// let appVersion: any = null;

// (async () => {
//   try {
//     appVersion = await getVersionAsync();
//     if (appVersion) {
//       console.log(`App version: ${appVersion}`);
//     }
//   } catch (error) {
//     console.error("Error:", error);
//   }
// })();

/**
 * Function to get the authentication token from cookies
 */
const getAuthToken = () => cookies.get("token") || "";

const currentAppVersion = () => {
  const version = localStorage.getItem("appVersion");
  return version;
};

const refreshAuthLogic = async (failedRequest: any) => {
  const refreshToken = cookies.get("refreshToken");
  if (failedRequest.config?.url === "/admin/login") {
    return Promise.reject(failedRequest);
  } else if (!refreshToken) {
    cookies.remove("token", { path: "/" });
    cookies.remove("refreshToken", { path: "/" });
    localStorage.clear();
    window.location.href = "/auth/signin";
    return Promise.reject(failedRequest);
  }

  try {
    const tokenRefreshResponse = await axios.post(
      `${baseURL}/admin/exchange-token`,
      {
        refresh_token: refreshToken,
      },
      {
        headers: {
          app_name: `${process.env.REACT_APP_BRAND_NAME}`,
        },
      }
    );
    const newAccessToken = tokenRefreshResponse?.data?.data?.access_token;
    const newRefreshTokenToken =
      tokenRefreshResponse?.data?.data?.refresh_token;
    const newPermissions = tokenRefreshResponse?.data?.data?.permissions;
    cookies.set("token", newAccessToken, { path: "/" });
    cookies.set("refreshToken", newRefreshTokenToken, { path: "/" });

    if (newPermissions) {
      store.dispatch(
        updateAuthPermissions(convertKeysToCamelCase(newPermissions))
      );
    }
    if (newAccessToken) {
      failedRequest.config.headers["Authorization"] =
        `Bearer ${newAccessToken}`;

      return axios(failedRequest.config);
    } else {
      return Promise.reject("New token not received");
    }
  } catch (error: any) {
    cookies.remove("token", { path: "/" });
    cookies.remove("refreshToken", { path: "/" });
    localStorage.clear();
    window.location.href = "/auth/signin";
    return Promise.reject(error);
  }
};

/**
 * API client instance.
 */
const apiClient = axios.create({
  baseURL: baseURL,
});

const refreshAuthInterceptor = createAuthRefreshInterceptor(
  apiClient,
  refreshAuthLogic
);

apiClient.interceptors.request.use(
  async (config: any) => {
    // store.dispatch(setLoadingProgress(getRandomProgress()));
    const authToken = getAuthToken();
    config.headers = config.headers || {};
    config.headers["Authorization"] = `Bearer ${authToken}`;
    config.headers["timezone"] = `${getCurrentZone()}`;
    config.headers["app_name"] = `${process.env.REACT_APP_BRAND_NAME}`;

    if (
      (config.url.includes("dynamic-forms") ||
        config.url.includes("/admin/survey-and-polls")) &&
      config.url.includes("fields" || "fill-survey")
    ) {
      return config;
    }

    if (
      config.url === `${baseURL}/admin/observations` &&
      config.method === "put"
    ) {
      return config;
    }
    if (config.data) {
      config.data = convertKeysToSnakeCase(config.data);
    }

    if (config.params) {
      config.params = convertKeysToSnakeCase(config.params);
    }
    // store.dispatch(setLoadingProgress(getRandomProgress()));

    return config;
  },
  (error) => {
    // store.dispatch(setLoadingProgress(100));
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => {
    if (currentEnvironment !== "localhost") {
      const appVersion = currentAppVersion();
      const updatedAppVersion = response.headers["react-version"];
      if (
        shouldShowModal() &&
        appVersion &&
        updatedAppVersion &&
        updatedAppVersion !== appVersion
      ) {
        store.dispatch(
          openNotifyAboutNewVersionModalAction({
            currentVersion: appVersion,
            updatedVersion: updatedAppVersion,
          })
        );
      } else if (updatedAppVersion === appVersion) {
        localStorage.removeItem("lastUpdateDismissed");
        localStorage.setItem("appVersion", updatedAppVersion);
      } else if (!appVersion) {
        localStorage.setItem("appVersion", updatedAppVersion);
      }
    }

    if (response.data) {
      response.data = convertKeysToCamelCase(response.data);
    }
    // store.dispatch(setLoadingProgress(100));
    return response;
  },
  (error) => {
    // store.dispatch(setLoadingProgress(100));

    if (
      error.response &&
      error.response.status === 401 &&
      error.config?.url !== "/admin/login"
    ) {
      createAuthRefreshInterceptor(apiClient, refreshAuthLogic);

      // return refreshAuthInterceptor;
    } else if (error.response && error.response.status === 403) {
      const { grace_period_expired } = error.response.data.data;
      if (grace_period_expired) {
      // const errorMsg = error?.response?.data?.message ?? error.message;
      // Toasts.error(errorMsg);
      setTimeout(() => {
        cookies.remove("token", { path: "/" });
        cookies.remove("refreshToken", { path: "/" });
        localStorage.clear();
        window.location.href = "/auth/signin";
      }, 1000);
      }
    }
    return Promise.reject(error);
    }
  );

  apiClient.interceptors.request.use(requestInterceptor);
  apiClient.interceptors.response.use(responseInterceptor, errorHandler);
  apiClient.interceptors.request.use(addAuthorizationHeader);

  function addAuthorizationHeader(config: any) {
    const authToken = getAuthToken();
    if (authToken) {
    config.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return config;
  }

  export default apiClient;
// import Cookies from "universal-cookie";
// import { baseURL } from "../../config/index";
// import createAuthRefreshInterceptor from "axios-auth-refresh";
// import {
//   convertKeysToCamelCase,
//   convertKeysToSnakeCase,
// } from "utils/caseConvertor";
// import {
//   errorHandler,
//   requestInterceptor,
//   responseInterceptor,
// } from "./interceptors";
// import { Toasts } from "view/components/Toasts";

// const cookies = new Cookies();
// let cancelTokens: { [key: string]: CancelTokenSource } = {};
// let activeRequests: any = [];

// const getEndpointOfUrl = (req: any) => {
//   let fullUrl = new URL(`${req?.baseURL + req?.url}`);
//   return fullUrl.pathname;
// };

// const removeExistingEndpoint = (request: any) => {
//   const index = activeRequests.findIndex(
//     (req: any) =>
//       req.url === getEndpointOfUrl(request) &&
//       req.body === JSON.stringify(request.data) &&
//       req.method === request.method &&
//       req.componentId === request.headers.componentid
//   );
//   if (index > -1) {
//     activeRequests.splice(index, 1);
//   }
// };

// /**
//  * Function to get the authentication token from cookies
//  */
// const getAuthToken = () => cookies.get("token") || "";

// const refreshAuthLogic = (failedRequest: any) => {
//   const refreshToken = cookies.get("refreshToken");
//   if (failedRequest.config?.url === "/auth/signin") {
//     return Promise.reject(failedRequest);
//   }

//   if (!refreshToken) {
//     // cookies.remove("token", { path: "/" });
//     // cookies.remove("refreshToken", { path: "/" });
//     // localStorage.clear()
//     // Toasts.error("Refresh token not found");
//     // window.location.href = "/auth/signin";
//   }

//   return axios
//     .post(`${baseURL}/admin/exchange-token`, {
//       refresh_token: refreshToken,
//     })
//     .then((tokenRefreshResponse) => {
//       const newToken = tokenRefreshResponse?.data?.data?.access_token;
//       cookies.set("token", newToken, { path: "/" });

//       if (newToken) {
//         // Update the Authorization header in the failed request
//         failedRequest.config.headers["Authorization"] = `Bearer ${newToken}`;
//         // Retry the failed request with the updated token
//         return axios(failedRequest.config);
//       }

//       return Promise.reject("New token not received");
//     })
//     .catch((error: any) => {
//       cookies.remove("token", { path: "/" });
//       cookies.remove("refreshToken", { path: "/" });
//       localStorage.clear();
//       window.location.href = "/auth/signin";
//       return Promise.reject(error);
//     });
// };

// const apiClient = axios.create({
//   baseURL: baseURL,
// });

// const refreshAuthInterceptor = createAuthRefreshInterceptor(
//   apiClient,
//   refreshAuthLogic
// );

// apiClient.interceptors.request.use(
//   (config: any) => {
//     const authToken = getAuthToken();
//     config.headers = config.headers || {};
//     config.headers["Authorization"] = `Bearer ${authToken}`;

//     if (config.url.includes("dynamic-forms") && config.url.includes("fields")) {
//       return config;
//     }

//     if (config.data) {
//       config.data = convertKeysToSnakeCase(config.data);
//     }

//     if (config.params) {
//       config.params = convertKeysToSnakeCase(config.params);
//     }

//     const componentId = config.headers.componentId;

//     activeRequests
//       .filter(
//         (item: any) =>
//           item.url === getEndpointOfUrl(config) &&
//           item.body === JSON.stringify(config.data) &&
//           item.method === config.method &&
//           item.componentId === componentId
//       )
//       .forEach((item: any) => {
//         cancelTokens[item.componentId].cancel();
//       });

//     if (cancelTokens[componentId]) {
//       cancelTokens[componentId] = axios.CancelToken.source();
//     } else {
//       cancelTokens[componentId] = axios.CancelToken.source();
//     }

//     config.cancelToken = cancelTokens[componentId].token;

//     activeRequests.push({
//       url: getEndpointOfUrl(config),
//       body: JSON.stringify(config.data),
//       method: config.method,
//       componentId,
//     });

//     return config;
//   },
//   (error: AxiosError) => {
//     return Promise.reject(error);
//   }
// );

// apiClient.interceptors.response.use(
//   (response: AxiosResponse<any>) => {
//     if (response.data) {
//       response.data = convertKeysToCamelCase(response.data);
//     }
//     return response;
//   },
//   (error: AxiosError<any>) => {
//     if (
//       error.response &&
//       error.response.status === 401 &&
//       error.config?.url !== "/auth/signin"
//     ) {
//       return refreshAuthInterceptor;
//     }

//     return Promise.reject(error);
//   }
// );

// const addAuthorizationHeader = (config: any) => {
//   const authToken = getAuthToken();
//   if (authToken) {
//     config.headers["Authorization"] = `Bearer ${authToken}`;
//   }
//   return config;
// };

// apiClient.interceptors.request.use(requestInterceptor);
// apiClient.interceptors.response.use(responseInterceptor, errorHandler);

// apiClient.interceptors.request.use(addAuthorizationHeader);

// export default apiClient;

function getCurrentZone() {
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZone;
}

function shouldShowModal() {
  const isModalAlreadyOpen =
    store.getState()?.modals?.notifyAboutNewVersionModal?.isOpen ?? false;
  const lastUpdateDismissed: any = localStorage.getItem("lastUpdateDismissed");
  if (!lastUpdateDismissed && !isModalAlreadyOpen) {
    return true;
  }
  const lastUpdateDismissedMoment = moment(parseInt(lastUpdateDismissed));
  const hoursSinceDismissed = moment().diff(lastUpdateDismissedMoment, "hours");

  return hoursSinceDismissed >= 1 && !isModalAlreadyOpen;
}
