/* eslint-disable no-unsafe-optional-chaining */
import React, { useRef, useState } from "react";
/************ Components ************/
import { Toasts } from "view/components/Toasts";
import { MAXIMUM_FILE_SIZE } from "view/components/Modals//Users/AddPerson";
import Status from "view/components/Statuses";
import { CustomizedSwitches } from "view/components/CustomizedSwitches";
/************ hooks ************/
import UseRoles from "hooks/roles";
import useProfileLinks from "./profileSideBarRoutes";
import useDateFormatter from "hooks/formatDateWithPattern";
/************ Utils ************/
import { convertKeysToCamelCase } from "utils/caseConvertor";
/************ API Services ************/
import apiLibrary from "services/api";
/************ Store Utils ************/
import { updateAuthDataAction } from "store/auth/reducer.actions";
import { RootState } from "store";
import { UserProfileState } from "store/userProfile/initialState";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAction } from "store/userProfile";
/************ Assets ************/
import Camera1Icon from "assets/icons/HeroIcons/Camera1Icon";
/************ External Library ************/
import { Link } from "react-router-dom";
import DarkModeToggler from "view/components/DarkModeToggler";
import { toggleDarkMode } from "store/darkMode/reducer.actions";
import { DarkMode } from "store/darkMode/initialState";
import { AnyAction } from "redux";
import { ThunkDispatch } from "redux-thunk";
import Restricted from "view/components/Restricted";
import usePermissions from "hooks/usePermissions";
import { getBrandPlaceholder } from "utils/customBranding";
import ellipsize from "ellipsize";

interface IProps {
  personId?: any;
}

const ProfileSideBar: React.FC<IProps> = () => {
  const [isLoading, setIsLoading] = useState(false);
  const { isDarkMode } = useSelector<RootState, DarkMode>(
    (state) => state?.darkMode
  );

  const { visibleLinks, handleLinkClick } = useProfileLinks();
  const { formatDate } = useDateFormatter();
  const { isMyProfile } = UseRoles();

  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );

  const { users } = usePermissions();

  // DISPATCH HOOK
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();

  const fileInputRef = useRef<HTMLInputElement>(null);

  /**
   * Handles the upload of a user's profile image.
   * @param {React.ChangeEvent<HTMLInputElement>} event - The event triggered by selecting a file for upload.
   */
  const handleImageUpload = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files?.[0];
    if (file && users.canEditPersonProfile) {
      try {
        const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];

        if (!validImageTypes.includes(file.type)) {
          return Toasts.error(
            "Please select a valid image file (jpeg/jpg/png)"
          );
        }
        const fileSize = file.size / 1024 / 1024; // Calculate the size of the uploaded file in megabytes.
        if (fileSize > MAXIMUM_FILE_SIZE) {
          return Toasts.error("File size must be less than 10 mb!");
        }

        setIsLoading(true);

        const uploadResponse = await apiLibrary.file.fileUpload(file);

        const { data } = await apiLibrary.userProfile?.updateUserProfile(
          userProfile.personId,
          {
            person_image_id: uploadResponse.data.id,
          }
        );

        const convertedData = convertKeysToCamelCase(data);

        if (isMyProfile(convertedData.personId)) {
          dispatch(updateAuthDataAction(convertedData));
        }
        dispatch(getUserProfileAction(convertedData));
      } catch (error: any) {
        const errorMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errorMsg);
      } finally {
        setIsLoading(false);
      }
    }
  };

  /**
   * Handles the click event to trigger the file input element.
   */
  const handleClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleDarkMode = () => {
    dispatch(toggleDarkMode());
  };

  return (
    <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0 rounded-lg bg-bgWhite dark:bg-secondaryLight">
      <div className="flex flex-col items-start justify-start">
        <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 px-3 pt-2 pb-8 bg-white">
          <div className="flex flex-col items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-2 py-4">
            <div className="flex flex-col-reverse items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              <div className="flex flex-col items-start justify-start flex-grow-0 flex-shrink-0">
                <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 rounded-lg">
                  <div className="relative flex flex-col items-center justify-center flex-grow">
                    <p className="mb-1 max-h-32 text-center overflow-hidden text-overflow-ellipsis whitespace-pre-line max-w-[200px] break-all  dark:text-textMain">
                      {ellipsize(userProfile?.firstName, 20)}
                      {ellipsize(userProfile?.lastName, 20)}
                    </p>

                    {/* {userProfile?.status && userProfile?.status !== null && (
                      <Status status={userProfile?.status} />
                    )} */}
                  </div>
                </div>
                <div className="flex items-center self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 rounded-lg">
                  <div className="flex justify-center flex-col items-center flex-grow relative py-0.5 dark:text-textMain">
                    {userProfile?.memberSince && (
                      <>
                        <b>Member Since</b>
                        <p className="capitalize dark:text-textMain">
                          {formatDate(userProfile?.memberSince)}
                        </p>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-row-reverse items-start self-stretch justify-between flex-grow-0 flex-shrink-0 py-3">
                  <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid  dark:text-textMain">
                      {userProfile?.communities}
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-secondaryMid  dark:text-textMain">
                      Communities
                    </p>
                  </div>
                  <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid  dark:text-textMain">
                      {userProfile?.forms}
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 w-[70px] text-xs text-center text-secondaryMid  dark:text-textMain">
                      Forms
                    </p>
                  </div>
                  <div className="flex flex-col justify-start items-center flex-grow-0 flex-shrink-0 relative gap-1.5">
                    <p className="flex-grow-0 flex-shrink-0 text-sm text-center text-secondaryMid  dark:text-textMain">
                      {userProfile?.observations}
                    </p>
                    <p className="flex-grow-0 flex-shrink-0 text-xs text-center text-secondaryMid  dark:text-textMain">
                      Observations
                    </p>
                  </div>
                </div>
              </div>
              <div className="relative flex items-start self-stretch justify-center flex-grow-0 flex-shrink-0 pb-2">
                <div className="relative rounded-full w-28 h-28">
                  {isLoading && (
                    <div className="absolute top-0 left-0 flex items-center justify-center w-full h-full bg-gray-500 bg-opacity-50">
                      <div className="inline-flex items-center">
                        <svg
                          className="w-8 h-8 animate-spin text-primaryExtraLight"
                          xmlns="https://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  )}
                  <input
                    disabled={isLoading}
                    type="file"
                    className="absolute top-0 left-0 hidden opacity-0 cursor-pointer"
                    multiple={false}
                    ref={fileInputRef}
                    accept="image/jpeg, image/jpg, image/png"
                    onChange={handleImageUpload}
                  />
                  <img
                    className="object-cover w-full h-full rounded-full"
                    src={
                      userProfile?.personImage?.file
                        ? userProfile?.personImage?.file?.original
                        : getBrandPlaceholder("userProfile")
                    }
                    alt="User Profile"
                  />
                  {users.canEditPersonProfile && (
                    <button
                      disabled={isLoading}
                      onClick={handleClick}
                      className="flex bg-bgWhite dark:bg-secondaryLight disabled:cursor-not-allowed justify-start items-start absolute left-[90px] top-[76px] gap-2 p-0.5 rounded-3xl bg-white"
                    >
                      <Camera1Icon />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="flex flex-col items-start self-stretch justify-start flex-grow-0 flex-shrink-0 gap-1">
            {visibleLinks.map((link) => {
              // if (link.label === "Dark Theme") {
              //   return (
              //     <button
              //       key={link.label}
              //       className="relative flex items-center group self-stretch justify-start flex-grow-0 flex-shrink-0 gap-4 px-3 py-2 mb-2 rounded-lg hover:bg-primaryExtraLight"
              //     >
              //       {React.cloneElement(link.icon, {
              //         fill: link.isActive ? "#005C89" : "#2C3236",
              //         isActive: link.isActive,
              //       })}
              //       <div className="relative flex items-center justify-between flex-grow">
              //         <p className="flex justify-start items-center group-hover:dark:text-primary flex-grow relative py-0.5 text-secondary dark:text-inputText">
              //           {link.label}
              //         </p>
              //         <div className="flex justify-center items-center absolute left-[70%]">
              //           <CustomizedSwitches
              //             label=""
              //             checked={isDarkMode}
              //             onChange={handleDarkMode}
              //           />
              //         </div>
              //       </div>
              //     </button>
              //   );
              // }
              if (link.type === "button") {
                return (
                  <div
                    className="w-full border-t group border-lineMid dark:border-lineLight"
                    key={link.label}
                  >
                    <button
                      key={link.label}
                      className={`mt-3 w-full flex justify-start items-center self-stretch relative gap-4 px-3 py-2 rounded-lg hover:bg-primaryExtraLight ${
                        link.isActive
                          ? "bg-primaryExtraLight text-primary font-semibold"
                          : "text-black "
                      }`}
                      onClick={() => {
                        handleLinkClick(link.label);
                        link.onClick();
                      }}
                    >
                      {React.cloneElement(link.icon, {
                        fill: link.isActive ? "#005C89" : "#2C3236",
                        isActive: link.isActive,
                      })}
                      <p className="relative py-0.5 text-secondary dark:text-inputText group-hover:dark:text-primary">
                        {link.label}
                      </p>
                    </button>
                  </div>
                );
              }
              return (
                <React.Fragment key={link.label}>
                  {link.label === "Settings" ||
                  link.label === "Notifications" ? (
                    <Restricted
                      permissions={users}
                      requiredPermission={
                        link.label === "Settings"
                          ? "canViewSettings"
                          : "canViewNotification"
                      }
                    >
                      <Link
                        to={link.url}
                        className={`flex group justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-4 px-3 py-2 rounded-lg hover:bg-primaryExtraLight ${
                          link.isActive
                            ? "bg-primaryExtraLight text-primary font-semibold"
                            : "text-secondary dark:text-inputText"
                        }`}
                        onClick={() => handleLinkClick(link.label)}
                      >
                        {React.cloneElement(link.icon, {
                          fill: link.isActive ? "#005C89" : "#2C3236",
                          isActive: link.isActive,
                        })}
                        <p className="relative py-0.5 group-hover:dark:text-primary">
                          {link.label}
                        </p>
                      </Link>
                    </Restricted>
                  ) : (
                    <Link
                      key={link.label}
                      to={link.url}
                      className={`flex group justify-start items-center self-stretch flex-grow-0 flex-shrink-0 relative gap-4 px-3 py-2 rounded-lg hover:bg-primaryExtraLight ${
                        link.isActive
                          ? "bg-primaryExtraLight text-primary font-semibold"
                          : "text-secondary dark:text-inputText"
                      }`}
                      onClick={() => handleLinkClick(link.label)}
                    >
                      {React.cloneElement(link.icon, {
                        fill: link.isActive ? "#005C89" : "#2C3236",
                        isActive: link.isActive,
                      })}
                      <p className="relative py-0.5 group-hover:dark:text-primary">
                        {link.label}
                      </p>
                    </Link>
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProfileSideBar;
