import React, { useState } from "react";
import _ from "lodash";
import PhoneCreator from "./components";
import { FieldArray } from "formik";
import { useSelector } from "react-redux";
import { UserProfileState } from "store/userProfile/initialState";
import { RootState } from "store";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import * as Yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";
import ChangePrimaryPhoneNumber from "./components/ChangePrimaryPhoneNumber";
import { CountryCode } from "libphonenumber-js";

const PhoneBox: React.FC<{
  values: any;
  errors: any;
  handleChange?: any;
  handleBlur?: any;
  touched?: any;
  setFieldValue: any;
  updatePersonData: any;
  isLoading: boolean;
  refreshNewData: (data: any) => void;
}> = ({
  values,
  errors,
  handleChange,
  handleBlur,
  touched,
  setFieldValue,
  updatePersonData,
  isLoading,
  refreshNewData,
}) => {
  const userProfile = useSelector<RootState, UserProfileState>(
    (state) => state?.userProfile
  );
  const [newPhoneNumber, setNewPhoneNumber] = useState<{
    phoneNumber: string;
    countryCode: string;
  }>({
    phoneNumber: "",
    countryCode: "US",
  });
  const [hasError, setHasError] = useState<string | null>(null);

  const validatePhoneNumber = async (phone: string) => {
    try {
      await Yup.string()
        .required("Phone Number is required")
        .test({
          name: "isValidPhone",
          exclusive: true,
          message: "Invalid phone number",
          test: function (value) {
            if (value) {
              const phoneNumber = isValidPhoneNumber(value);
              return phoneNumber;
            } else {
              return true;
            }
          },
        })
        .validate(phone);

      setHasError(null);
    } catch (error: any) {
      setHasError(error.message);
    }
  };

  const addNewPhoneNumber = async () => {
    await validatePhoneNumber(newPhoneNumber.phoneNumber);

    if (!hasError) {
      const updatedValue = { ...values };

      const updatedPhoneNumber = [
        ...updatedValue.secondaryPhoneNumber,
        {
          phoneNumber: newPhoneNumber.phoneNumber,
          countryCode: newPhoneNumber.countryCode,
          isPrimary: false,
          isVerified: false,
        },
      ];

      updatedValue.secondaryPhoneNumber = updatedPhoneNumber;

      await updatePersonData(updatedValue);
      setNewPhoneNumber({
        phoneNumber: "",
        countryCode: "",
      });
    }
  };

  return (
    <div className="flex flex-col items-start justify-start w-full mt-6">
      <ChangePrimaryPhoneNumber refreshNewData={refreshNewData} />

      <FieldArray name="secondaryPhoneNumber">
        {({ push, remove }) => (
          <>
            <div className="w-full mt-3">
              <div className="w-full border rounded-md border-lineMid dark:border-lineLight">
                {values.secondaryPhoneNumber.map(
                  (contact: any, index: number) => {
                    return (
                      <PhoneCreator
                        remove={remove}
                        key={index}
                        values={values}
                        currentPhoneIndex={index}
                        contact={contact}
                        touched={touched}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        errors={errors}
                        setFieldValue={setFieldValue}
                        refreshNewData={refreshNewData}
                      />
                    );
                  }
                )}
              </div>
            </div>
            {!userProfile.virtual && (
              <div className="flex items-center ">
                <div className="w-[300px]">
                  <TextInput
                    height={38}
                    fieldAs="phone"
                    label="Add phone number"
                    type="text"
                    placeholder={"Phone number"}
                    name="phoneNumber"
                    value={newPhoneNumber.phoneNumber}
                    error={hasError}
                    touched={true}
                    handleChange={(value: any) => {
                      setNewPhoneNumber(
                        (pre: { phoneNumber: string; countryCode: string }) => {
                          return {
                            ...pre,
                            phoneNumber: value,
                          };
                        }
                      );
                      validatePhoneNumber(value);
                    }}
                    countryChange={(country: CountryCode) => {
                      setNewPhoneNumber(
                        (pre: { phoneNumber: string; countryCode: string }) => {
                          return {
                            phoneNumber: "",
                            countryCode: country,
                          };
                        }
                      );
                    }}
                    handleBlur={handleBlur}
                    setFieldValue={setFieldValue}
                  />
                </div>
                <button
                  disabled={
                    !newPhoneNumber.phoneNumber ||
                    hasError !== null ||
                    isLoading
                  }
                  type="button"
                  className={`border disabled:cursor-not-allowed border-lineMid dark:border-lineLight rounded ml-3 h-[40px] w-[70px] ${hasError ? "mt-2" : "mt-6"} bg-primary text-textMain dark:text-caption`}
                  onClick={addNewPhoneNumber}
                >
                  Add
                </button>
              </div>
            )}
          </>
        )}
      </FieldArray>
    </div>
  );
};

export { PhoneBox };
